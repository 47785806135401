import React from 'react'
import {
  CFLink,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderPickupButton } from 'images'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/z4dCJ8BrT2FGhj0CqMwT/locations/Dn6nksY1c6HyVRe3qV6V'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFLink href={orderingUrl}>
          <CFView hover>
            <CFImage src={orderPickupButton} maxWidth="300px" alt="About" />
          </CFView>
        </CFLink>
      </MobileScreen>
      <DefaultScreen>
        <a href={orderingUrl}>
          <CFView hover>
            <CFImage src={orderPickupButton} maxWidth="350px" alt="About" />
          </CFView>
        </a>
      </DefaultScreen>
    </CFView>
  )
}
