import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          column
          justifyEnd
          h="90VH"
          w="100%"
          image={`url(${mobileHero}) top / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <CFView column center pb="40px" ph="10px">
            <CFView>
              <CFView
                column
                center
                bg="rgba(0,0,0,0.7)"
                p="10px 20px"
                br="10px"
              >
                <CFView bold h2 textCenter color="white">
                  Now Taking Online Orders!
                </CFView>
                <CFView column center>
                  <OrderPickupButton />
                  <OrderDeliveryButton />
                </CFView>
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="620px"
          w="100%"
          image={`url(${hero}) right / cover no-repeat`}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
        >
          <CFView relative row justifyStart pb="30px">
            <CFView
              pt="50px"
              bg="rgba(0,0,0,.75)"
              minWidth="450px"
              column
              center
              w="30%"
              h="620px"
              ml="9%"
            >
              <CFView textCenter ph="40px" mb="40px">
                <CFImage
                  src={heroText}
                  maxWidth="300px"
                  alt="About"
                  mb="15px"
                />
                <OrderPickupButton />
                <OrderDeliveryButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
