import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { mobilePromotion, promotions } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center pt="30px">
          <CFImage src={mobilePromotion} w="98%" alt="Promotions" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="0 auto" maxWidth="1400px" pv="50px">
          <CFImage src={promotions} w="90%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
