import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
  CFLink,
} from 'components'
import {
  logo,
  logoTitle,
  viewMenu,
  menu,
  menu1,
  menu2,
  menu3,
  menu4,
  menu5,
} from 'images'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/nqeoaepgw1d0XIULFk73/locations/PcCPpuqPbaOEZeLx9xHC'

const images = [
  {
    src: menu1,
  },
  {
    src: menu2,
  },
  {
    src: menu3,
  },
  {
    src: menu4,
  },
  {
    src: menu5,
  },
]

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          column
          center
          h="90px"
          bg="#FF9F00"
          boxShadow="0 1px 3px rgba(0,0,0,.8)"
        >
          <CFView row center alignCenter>
            <CFImage
              h="50px"
              mt="10px"
              src={logo}
              alt="JTL Chicken World Logo"
            />
            <CFImage
              h="40px"
              ml="15px"
              src={logoTitle}
              alt="JTL Chicken World Logo Title"
            />
          </CFView>
          <CFLink href={menu} target="_blank">
            <CFImage
              mt="-15px"
              h="50px"
              src={viewMenu}
              alt="JTL Chicken World View Menu Button"
            />
          </CFLink>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          h="70px"
          bg="#FF9F00"
          boxShadow="0 1px 3px rgba(0,0,0,.8)"
          zIndex={99}
        >
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="35px"
          >
            <CFView row justifyStart mt="75px">
              <CFImage
                h="140px"
                mt="5px"
                src={logo}
                alt="JTL Chicken World Logo"
                zIndex={99}
              />
              <CFImage
                h="50px"
                mt="10px"
                src={logoTitle}
                alt="JTL Chicken World Logo Title"
              />
            </CFView>
            {/* <CFView hover mt="9px">
              <CFLink href={orderingUrl}>
                <CFImage
                  h="60px"
                  src={viewMenu}
                  alt="JTL Chicken World View Menu Button"
                />
              </CFLink>
            </CFView> */}
            <CFLink href={menu} target="_blank">
              <CFImage
                pt="8px"
                h="65px"
                src={viewMenu}
                alt="JTL Chicken World View Menu Button"
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
