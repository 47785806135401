import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h2 futura bold color="#FF9F00" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.facebook.com/JTL-Chicken-World-1846765908971635/">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="JTL Chicken World Facebook"
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/jtl-chicken-world-coquitlam">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="JTL Chicken World Yelp"
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/jtl-chicken-world-coquitlam-coquitlam">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="JTL Chicken World Zomato"
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/jtl_chicken_world/">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="JTL Chicken World Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h2 futura bold color="#FF9F00" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.facebook.com/JTL-Chicken-World-1846765908971635/">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="JTL Chicken World Facebook"
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/jtl-chicken-world-coquitlam">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="JTL Chicken World Yelp"
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/jtl-chicken-world-coquitlam-coquitlam">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="JTL Chicken World Zomato"
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/jtl_chicken_world/">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="JTL Chicken World Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
