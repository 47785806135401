import React from 'react'
import {
  CFLink,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { doordash } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFLink
          newTab
          href="https://www.doordash.com/store/jtl-chicken-world-coquitlam-204559/en-US/?utm_campaign=gpa"
        >
          <CFImage src={doordash} maxWidth="290px" alt="About" />
        </CFLink>
      </MobileScreen>
      <DefaultScreen>
        <CFLink
          newTab
          href="https://www.doordash.com/store/jtl-chicken-world-coquitlam-204559/en-US/?utm_campaign=gpa"
        >
          <CFView hover>
            <CFImage src={doordash} maxWidth="340px" alt="About" />
          </CFView>
        </CFLink>
      </DefaultScreen>
    </CFView>
  )
}
